import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const BikeSecton = styled.section`
  box-sizing: inherit;
  display: block;
  overflow: hidden;
  padding: 3rem 0;
  padding-top: 5rem !important;
  border: none;
  background-color: #f5f5f5;
  .bike__container {
    font-size: 16px;
    box-sizing: inherit;
    margin: 0 auto;
    margin-top: 60px;
    max-width: 90rem;
    padding: 0;
    header {
      font-size: 16px;
      box-sizing: inherit;
      display: block;
      max-width: 54rem;
      margin: 0 auto;
      padding: 0;
      padding-bottom: 2rem !important;
      text-align: center !important;
    }
    h2 {
      font-size: 3.125rem;
      max-width: 54rem;
      margin: 0 auto;
      padding: 0;
      padding-bottom: 2rem !important;
      text-align: center !important;
    }
    p {
      margin: 0;
      font-weight: 400;
      line-height: 1.333;
      letter-spacing: -0.2px;
      margin-top: 0;
      margin-bottom: 1rem;
      font-size: 1.25rem;
    }
    a {
      -webkit-font-smoothing: antialiased;
      text-align: center !important;
      box-sizing: inherit;
      background: 0 0;
      color: #1264a3;
      cursor: pointer;
      text-decoration: none;
      word-break: break-word;
      font-weight: 400;
      line-height: 1.44444444;
      letter-spacing: normal;
      font-size: 1.125rem;
      display: inline-block;
      margin-bottom: 0;
      position: relative;
      border-bottom: none;
      width: 100% !important;
    }
  }
  .block__grid {
    box-sizing: inherit;
    margin-bottom: 1rem;
    list-style-type: none;
    display: grid;
    grid-gap: 1rem 5%;
    justify-content: center;
    padding-top: 0;
    margin-right: 5rem !important;
    margin-left: 5rem !important;
    justify-items: center !important;
    grid-template-columns: repeat(4, 1fr);
    .tile-rating {
      position: absolute;
      top: -16px;
      left: -25px;
      background: #ffd155;
      padding: 5px 10px;
      border-radius: 3px;
      font-weight: 700;
      font-size: 16px;
    }
    .bike-cards {
      list-style-type: none;
      box-sizing: inherit;
      text-decoration: none;
      word-break: break-word;
      width: 100%;
      max-width: 400px;
      margin: 15px auto;
      color: inherit;
      background: #fff;
      border: 6px solid #DFE1E2;
      text-align: center;
      display: block;
      padding: 10px 10px 35px;
      position: relative;
      z-index: 1;
      break-inside: avoid;
      flex-direction: column;
      transition: transform 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
      margin-bottom: 2.43902439%;
      margin-left: 0;
      flex-basis: 23.17%;
      margin-right: 0;
      .figure-logo {
        height: 80px !important;
        margin-bottom: 20px;
      }
      figure {
        height: 310px;
        list-style-type: none;
        word-break: break-word;
        color: inherit;
        text-align: center;
        box-sizing: inherit;
        display: block;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        .bike__image {
          list-style-type: none;
          word-break: break-word;
          color: inherit;
          text-align: center;
          box-sizing: inherit;
          border: 0;
          max-width: 100%;
          height: auto;
        }
        .bike__logo {
          list-style-type: none;
          word-break: break-word;
          color: inherit;
          text-align: center;
          box-sizing: inherit;
          border: 0;
          max-width: 55%;
          height: auto;
          transition: filter 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
          filter: grayscale(100%);
          margin-bottom: 50px;
        }
      }
      .bike__copy {
        list-style-type: none;
        word-break: break-word;
        color: inherit;
        text-align: center;
        box-sizing: inherit;
        font-weight: 400;
        line-height: 1.44444444;
        letter-spacing: normal;
        font-size: 1rem;
        margin: 0;
        margin-bottom: 1rem;
      }
    }
  }
`

const TopRated = () => (
  <Layout>
    <SEO
      title="Top Rated Air Bikes for 2020"
      description="Check out the top rated Air Bikes for 2020"
    />
    <BikeSecton>
      <div className="bike__container">
        <header>
          <h2>Our Top Rated Air Bikes</h2>
        </header>
        <div className="block__grid">
          <div className="bike-cards">
            <div className="tile-rating">4.6/5</div>
            <figure>
              <img
                className="bike__image"
                src="/images/rogue-echo-bike.jpg"
                alt="rogue echo bike review"
              />
            </figure>
            <figure className="figure-logo">
              <img
                className="bike__logo"
                src="/images/logo/rogue.png"
                alt="rogue fitness"
              />
            </figure>
            <a href="https://www.roguefitness.com/rogue-echo-bike?a_aid=5e7fb64738ac3"><button className="btn-cta">See Best Price</button></a>
            <Link to="/rogue-echo-bike-review"><button className="btn-review">Read Review</button></Link>
          </div>

          <div className="bike-cards">
            <div className="tile-rating">4.5/5</div>
            <figure>
              <img
                className="bike__image"
                src="/images/bike-erg.jpg"
                alt="Concept 2 Bike Erg"
              />
            </figure>
            <figure className="figure-logo">
              <img
                className="bike__logo"
                src="/images/logo/concept2.png"
                alt="concept 2"
              />
            </figure>
            <a href="https://www.roguefitness.com/concept2-bike-erg?a_aid=5e7fb64738ac3"><button className="btn-cta">See Best Price</button></a>
            <Link to="/concept2-bike-erg-review"><button className="btn-review">Read Review</button></Link>
          </div>

          <div className="bike-cards">
            <div className="tile-rating">4.2/5</div>
            <figure>
              <img
                className="bike__image"
                src="/images/assault-airbike.jpg"
                alt="Assault Airbike Review"
              />
            </figure>
            <figure className="figure-logo">
              <img
                className="bike__logo"
                src="/images/logo/assault_fitness.png"
                alt="Assault Airbike"
              />
            </figure>
            <a href="https://www.roguefitness.com/assault-airbike-and-accessories?a_aid=5e7fb64738ac3"><button className="btn-cta">See Best Price</button></a>
            <Link to="/assault-airbike-review"><button className="btn-review">Read Review</button></Link>
          </div>

          <div className="bike-cards">
            <div className="tile-rating">4.0/5</div>
            <figure>
              <img
                width="85%"
                className="bike__image"
                src="/images/schwinn-airdyne-ad7.png"
                alt="Schwinn AirDyne Pro review"
              />
            </figure>
            <figure className="figure-logo">
              <img
                className="bike__logo"
                src="/images/logo/Schwinn.png"
                alt="Schwinn AirDyne"
              />
            </figure>
            <a href="https://www.roguefitness.com/schwinn-airdyne-pro?a_aid=5e7fb64738ac3"><button className="btn-cta">See Best Price</button></a>
            <Link to="/schwinn-airdyne-pro-review"><button className="btn-review">Read Review</button></Link>
          </div>
        </div>
      </div>
    </BikeSecton>
  </Layout>
)

export default TopRated
